import WebViewer from "@pdftron/webviewer";
import {Once} from 'behavior/once';

export default class Redactomatic {
  constructor(el) {
    this.el = el;
    this.redactionSuggestions = [];
    this.unsavedRedactions = false;
  }

  get containerSelector() { return this.el.dataset.container }
  get container() { return this.containerSelector ? document.querySelector(this.containerSelector) : this.el }
  get user() { return this.el.dataset.user }
  get loadUrl() { return this.el.dataset.loadUrl }
  get saveUrl() { return this.el.dataset.saveUrl }
  get saveAs() { return this.el.dataset.saveAs || "Redacted.pdf" }

  startup() {
    if (!this.el) { return }

    this.el.innerHTML = "";
    this.el.classList.remove("redactomatic---loading");

    const applicationId = this.container.querySelector("[data-application-id]").dataset.applicationId;

    WebViewer.Iframe(
      {
        path: `/${document.body.parentElement.classList.contains("test") ? "packs-test": "packs"}/webviewer`,
        licenseKey: this.el.dataset.licenseKey,
        fullAPI: true,
        enableRedaction: true,
        initialDoc: this.loadUrl, // maybe use https://docs.apryse.com/documentation/web/guides/basics/open/arraybuffer/ to avoid the s3 redirect issue
        annotationUser: this.user,
      },
      this.el
    ).then(async (webviewer) => {
      this.#setupUI(webviewer);
      this.#setupUnsavedIndicator(webviewer);
      if (this.el.classList.contains("redactomatic---original-file")) {
        await this.#setRedactionSuggestions(applicationId);
        this.#initialRedactions(webviewer);
      }
      this.#setupSaving(webviewer);
      this.#disableSearchRedaction();
    });
  }

  async #setRedactionSuggestions(applicationId) {
    let redactomatic = this;
    await fetch(`/analyst/applications/${applicationId}/redaction_suggestions.json`)
      .then(response => response.json())
      .then(json => {
        redactomatic.redactionSuggestions = Object.values(json).flat(Infinity);
      })
      .catch(e => console.error(`Could not fetch redaction suggestions: ${e}`, e));
  }

  #initialRedactions(webviewer) {
    const {
      documentViewer,
      annotationManager,
      Annotations,
      Search
    } = webviewer.Core;

    documentViewer.addEventListener('documentLoaded', () => {
      const mode = Search.Mode.HIGHLIGHT | Search.Mode.REGEX | Search.Mode.WHOLE_WORD;
      const matchPattern = this.redactionSuggestions.join("|");
      const redactSearchResults = (result) => {
        const redactionAnnotation = new Annotations.RedactionAnnotation({
          Quads: result.quads.map(q => q.getPoints()),
          Author: this.user,
          PageNumber: result.pageNum,
        });
        redactionAnnotation.setCustomData("trn-annot-preview", documentViewer.getSelectedText(redactionAnnotation.PageNumber));

        annotationManager.addAnnotation(redactionAnnotation);
        annotationManager.drawAnnotationsFromList([redactionAnnotation]);
      }
      const searchOptions = {
        fullSearch: true,
        onResult: redactSearchResults
      };

      documentViewer.textSearchInit(matchPattern, mode, searchOptions);
    });
  }

  #setupUnsavedIndicator(webviewer) {
    const { annotationManager } = webviewer.Core;

    annotationManager.addEventListener('annotationChanged', (annotations, action, info) => {
      if (info.source == 'redactionApplied') {
        this.unsavedRedactions = true;
        this.el.classList.add('unsaved-redaction');
        this.#enableSaveButtons();
      }
      this.el.classList.toggle('unsaved-annotation', annotationManager.getAnnotationsList().length);
    });
  }

  #setupSaving(webviewer) {
    if (!this.saveUrl) { return }

    const Core = webviewer.Core;
    const { 
      documentViewer,
      annotationManager
    } = Core;

    async function getBlob() {
      // from https://docs.apryse.com/documentation/web/guides/basics/save/
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const data = await doc.getFileData({xfdfString, flags: Core.SaveOptions.LINEARIZED});
      return new Blob([new Uint8Array(data)], {type: 'application/pdf'});
    }

    this.container.querySelectorAll('[data-pdf-save]').forEach(el => {
      el.addEventListener('click', async (event) => {
        event.preventDefault();

        let blob = await getBlob();
        let formData = new FormData();
        formData.set('application_file[removal_strategy]', "replacing");
        formData.set('application_file[uploaded_data]', blob, this.saveAs);

        const createRedactedFileButtonPlaceholder = this.container.querySelector("button.create-redacted-file.once-placeholder");
        if (createRedactedFileButtonPlaceholder) {
          createRedactedFileButtonPlaceholder.querySelector(".fa-spinner").classList.remove("hidden");
        }

        ajaxTracking.fetch(null, this.saveUrl, {
          method: 'PATCH',
          body: formData,
          logAs: "save-redacted-version",
        })
          .then(response => response.json())
          .then(json => {
            FindAndUpdate.all(json)
              .then(() => {
                this.el.classList.remove('unsaved-redaction');
                this.el.classList.toggle('unsaved-annotation', annotationManager.getAnnotationsList().length);
                this.unsavedRedactions = false;

                const createRedactedFileButton = this.container.querySelector("button.create-redacted-file");
                Once.reset($(createRedactedFileButton));
              });
          });
      });
    })
  }

  #setupUI(webviewer) {
    const Core = webviewer.Core;
    const { documentViewer } = Core;

    const configUI = {
      "modularComponents": {
        "redactionPanelToggle": {
          "type": "toggleButton",
          "img": "icon-redact-panel",
          "dataElement": "redactionPanelToggle",
          "title": "Toggle redaction panel",
          "toggleElement": "redactionPanel",
        }
      },
      "modularHeaders": {
        "mainHeader": {
          "dataElement": "mainHeader",
          "placement": "top",
          "justifyContent": "end",
          "items": [
            "redactionPanelToggle"
          ]
        }
      },
      'panels': {
        'redactionPanel': {
          dataElement: 'redactionPanel',
          location: 'right',
          render: webviewer.UI.Panels.REDACTION,
        }
      }
    };

    webviewer.UI.importModularComponents(configUI);
    webviewer.UI.setToolMode('AnnotationCreateRedaction');
    webviewer.UI.disableElements(['contextMenuPopup', 'annotationCommentButton', 'annotationStyleEditButton']);

    documentViewer.addEventListener('documentLoaded', () => {
      this.el.classList.add("redactomatic---loaded");
    });
  }

  #enableSaveButtons() {
    this.container.querySelectorAll('[data-pdf-save]').forEach(el => el.disabled = false);
  }

  static get autoloader() {
    return this._autoloader = this._autoloader || new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          new Redactomatic(entry.target).startup();
          observer.unobserve(entry.target);
        }
      });
    }, {threshold: 0.5});
  }

  #redactomaticIframeContent() {
    return this.container.querySelector('iframe').contentDocument.body;
  }

  #disableSearchRedaction() {
    const observer = new MutationObserver(mutations => {
      const redactUserSearchResultButton = this.#redactomaticIframeContent().querySelector("button.redact-all-selected");
      if (redactUserSearchResultButton) {
        redactUserSearchResultButton.style.display = "none";
      }
    });
    observer.observe(this.#redactomaticIframeContent(), {childList: true, subtree: true});
  }

  static setup() {
    new BusyBody({
      selector: '.redactomatic',
      added: (el) => Redactomatic.autoloader.observe(el),
    });
  }
}
